import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  CloseButton,
  CustomerMessage,
  DialogInner,
  ExtensionDialog,
  ExtensionLink,
  HomeContainer,
  HomeWrapper,
  InnerWrapper,
} from "./Home.styles";
import TabHomeComponent from "../features/components/ProviderTabComponent";
import { TabContent } from "../features/components/TabContent";
import { filteringDownMessage, filteringDownProvider } from "../admin/utils";
import {
  useExtensionInstall,
  useEventListener,
  useKeyboardInput,
} from "../hooks/HomeHooks";
import {
  posterImageObserver,
  scrollPercentage,
} from "../features/utils/pageUtils";
import { Platform } from "../features/adminFeatures/tables/tableTypes";
import { FetchUserQueryUtilEnum } from "../features/user/userTypes";
import {
  FocusContext,
  pause,
  resume,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { Queries, queryClient, useTypedQueryData } from "../lib/react-query";
import { returnIndexFromProvider } from "../features/flix/flixTypes";
import { flixProviderSignal, useFlix } from "../features/flix/useFlix";
import {
  openExtensionDialog,
  userLoggedInSignal,
} from "../features/user/userSignals";
import { useHistory } from "react-router-dom";

const Home: React.FC = () => {
  const history = useHistory();
  const extensionInstall = useExtensionInstall();
  const provider = flixProviderSignal.value;
  const providerTab = returnIndexFromProvider(provider);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initFlixData = useTypedQueryData(Queries.FETCH_FLIX, provider) || [];
  useKeyboardInput();
  const filteringDown = queryClient.getQueryData<Platform[]>([
    FetchUserQueryUtilEnum.FETCH_FILTERING_STATUS,
  ]);

  useEventListener("DOMContentLoaded", posterImageObserver);
  useEventListener("scroll", () => scrollPercentage(providerTab));
  const [mounted, setMounted] = useState(false);
  const {  focusKey } = useFocusable({
    trackChildren: true,
    autoRestoreFocus: true,
  });

  useEffect(() => {
    if (initFlixData) {
      setMounted(true);
    }
  }, [initFlixData]);

  useEffect(() => {
    resume();
 
    return () => pause();
  }, [pause, resume]);

  return (
    <FocusContext.Provider value={focusKey}>
      <HomeWrapper id="home-wrapper">
        <Helmet>
          {history.location.pathname === "/movies" && (
            <meta
              name="description"
              content="These are the latest filtered new movies we have at ClearPlay; a technology that allows you to filter out profanity, nudity, and violence."
            />
          )}
        </Helmet>

        <HomeContainer
          loggedin={userLoggedInSignal.value.toString()}
          disableGutters={true}
          maxWidth={false}
        >
          {filteringDownProvider(filteringDown, provider)?.web_banner && (
            <CustomerMessage>
              <span className="down-message">
                <div style={{maxWidth: '65vw'}}>{filteringDownMessage(filteringDown, provider)}</div>
                {/* TODO! Setting this for Disney+, but will affect other platforms */}
                <a href="https://help.clearplay.com/docs/presentation-quality-issues-on-https-wwwdisneypluscom" target="_blank" rel="noreferrer">More details</a>
              </span>
            </CustomerMessage>
          )}
          {/* <ButtonGeneral text='fetch flix' onClick={() => fetchInitFlix(provider)}/> */}
          <TabHomeComponent
            loggedIn={userLoggedInSignal.value}
            providerTab={providerTab}
            // dialogShowing={user.extensionState.openExtensionDialog}
            mounted={mounted}
            setMounted={setMounted}
          />
          <TabContent
            loggedIn={userLoggedInSignal.value}
            value={provider}
            index={provider}
            provider={provider}
            filteringDown={filteringDown}
          />
        </HomeContainer>
        <ExtensionDialog
          open={openExtensionDialog.value}
          onClose={() => (openExtensionDialog.value = false)}
        >
          <InnerWrapper>
            <CloseButton
              fontSize={"large"}
              onClick={() => (openExtensionDialog.value = true)}
            />
            <DialogInner>
              Set up filtering options by installing Clearplay onto your
              computer.
            </DialogInner>
            <br />
            <DialogInner>
              The button below will send you to the Chrome Web Store.
            </DialogInner>
            <ExtensionLink onClick={() => extensionInstall()}>
              Install Clearplay
            </ExtensionLink>
          </InnerWrapper>
        </ExtensionDialog>
      </HomeWrapper>
    </FocusContext.Provider>
  );
};

export default memo(Home);
